import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useUsuario(
  filtro,
  filiaisId,
  tipoDeUsuario,
  perfil,
  page,
  pageSize,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(0);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const payLoad = {filtro, filiaisId, tipoDeUsuario, perfil, page, pageSize};
    try {
      await api.post(url.USUARIO.CONSULTA_FILTRO(), payLoad).then((res) => {
        setData(res?.data?.data || []);
        setQuantidade(res?.data?.total);
      });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [filtro, filiaisId, tipoDeUsuario, perfil, page, pageSize]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
