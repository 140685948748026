import React, {useState, Fragment} from 'react';
import {Card, CardBody, Row, Col} from 'reactstrap';
import {Divider, Form, Select, Steps, Switch} from 'antd';

import {Icon} from 'semantic-ui-react';

import Page from '~/components/Page';
import ButtonShared from '~/shared/Button';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';

import api from '~/services/api';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import useEmpresasDropdown from '~/actions/DropDown/useEmpresasDropdown';
const {Option} = Select;

function ModalTransferirUsuario({userId, functionClose}) {
  const [isLoading, setIsLoading] = useState(false);
  const [finalValues, setFinalValues] = useState({});
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [removerUsuarioDaEmpresaAtual, setRemoverUsuarioDaEmpresaAtual] =
    useState(false);
  const [transferirAtendimentos, setTransferirAtendimentos] = useState(false);
  const [selectedEmpresaIdAlvo, setSelectedEmpresaIdAlvo] = useState();
  const [removerUsuarioDasEquipes, setRemoverUsuarioDasEquipes] = useState();
  const [
    transferirApenasAtendimentosComPessoasCriadasPeloUsuario,
    setTransferirApenasAtendimentosComPessoasCriadasPeloUsuario,
  ] = useState();
  const [transferirImoveis, setTransferirImoveis] = useState();
  let {data: empresas} = useEmpresasDropdown();
  empresas = empresas.reduce((acc, empresa) => {
    acc.push({id: empresa.id, nome: empresa.nome});
    if (empresa.filiais) {
      empresa.filiais.forEach((filial) =>
        acc.push({id: filial.id, nome: filial.nome}),
      );
    }
    return acc;
  }, []);
  function onChangeEmpresaAlvo(empresaId) {
    setSelectedEmpresaIdAlvo(empresaId);
  }

  const nextStep = () => {
    form.validateFields().then(async (dados) => {
      setCurrentStep(currentStep + 1);
      let newValues = {...finalValues};

      setFinalValues({...newValues, ...dados});
    });
  };
  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  let empresaLogada = null;
  try {
    const empresaComAcesso = JSON.parse(localStorage.getItem('icaseEmpresas'));
    const empresaLogadaId = localStorage.getItem('icaseEmpresaId');
    empresaLogada = empresaComAcesso.find(
      (e) => e.empresaId === empresaLogadaId,
    );
  } catch (e) {
    console.log(e);
  }

  const contentStyle = {
    marginTop: 16,
  };

  const steps = [
    {
      title: 'Empresa alvo',
      content: (
        <Row className="p-4">
          <Col xs="12" md="12">
            <p className="heading-small text-muted mb-0">
              <small>Nome</small>
            </p>
            <Form.Item
              className="mb-2"
              name="empresaIdAlvo"
              rules={[
                {
                  required: true,
                  message: 'Nome da empresa',
                },
              ]}>
              <Select
                style={{width: '85%'}}
                placeholder=" Empresa"
                showSearch
                onChange={onChangeEmpresaAlvo}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, ''),
                    ) >= 0
                }>
                {empresas.map((item) => (
                  <Option key={item?.empresa?.id || item?.id}>
                    {item?.empresa?.nome || item?.nome}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Configurações da transferencia',
      content: (
        <Col xs="12" md="12">
          <Row>
            <div>
              <p className="heading-small text-muted mb-0">
                <small>Remover usuário das equipes?</small>
              </p>
              <Form.Item
                className="mb-0"
                valuePropName="checked"
                name="removerUsuarioDasEquipes">
                <Switch
                  checked={false}
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  size="small"
                  onChange={(value) => setRemoverUsuarioDasEquipes(value)}
                />
              </Form.Item>
            </div>
          </Row>
          <Row>
            <div>
              <p className="heading text-muted mb-0">
                <small>Remover usuário da empresa atual?</small>
              </p>
              <Form.Item
                valuePropName="checked"
                name="removerUsuarioDaEmpresaAtual"
                className="mb-0">
                <Switch
                  checked={false}
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  size="small"
                  onChange={(value) => setRemoverUsuarioDaEmpresaAtual(value)}
                />
              </Form.Item>
            </div>
          </Row>
          <Divider />
          <Row>
            <div>
              <p className="heading text-muted mb-0">
                <small>Transferir atendimento?</small>
              </p>
              <Form.Item
                className="mb-0"
                valuePropName="checked"
                name="transferirAtendimentos">
                <Switch
                  checked={false}
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  size="small"
                  onChange={(value) => setTransferirAtendimentos(value)}
                />
              </Form.Item>
            </div>
          </Row>
          <Row>
            <div>
              <p
                className="heading text-muted mb-0"
                hidden={!transferirAtendimentos}>
                <small>
                  Transferir apenas carteira própria e com clientes criados
                  pelos usuários?
                </small>
              </p>

              <Form.Item
                valuePropName="checked"
                className="mb-0"
                hidden={!transferirAtendimentos}
                name="transferirApenasAtendimentosComPessoasCriadasPeloUsuario">
                <Switch
                  checked={false}
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  size="small"
                  onChange={(value) =>
                    setTransferirApenasAtendimentosComPessoasCriadasPeloUsuario(
                      value,
                    )
                  }
                />
              </Form.Item>
            </div>
          </Row>
          <Divider />
          <Row>
            <div>
              <p className="heading text-muted mb-0">
                <small>Transferir imoveis?</small>
              </p>
              <Form.Item
                className="mb-0"
                valuePropName="checked"
                name="transferirImoveis">
                <Switch
                  checked={false}
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  size="small"
                  onChange={(value) => setTransferirImoveis(value)}
                />
              </Form.Item>
            </div>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Confirmação',
      content: (
        <Col>
          <></>
          <Row className="p-4">
            Empresa de origem: {empresaLogada?.empresa?.nome}
          </Row>
          <Row className="p-4">
            Transferir para empresa:{' '}
            {((empresaId) => {
              const empresaEncontrada = empresas.find(
                (e) => e.id === empresaId,
              );
              return empresaEncontrada ? empresaEncontrada.nome : 'null';
            })(selectedEmpresaIdAlvo)}
          </Row>
          <Row className="p-4">
            Remover o usuário da empresa atual:{' '}
            {removerUsuarioDaEmpresaAtual ? 'Sim' : 'Não'}
          </Row>
          <Row className="p-4">
            Remover usuário das equipes:{' '}
            {removerUsuarioDasEquipes ? ' Sim' : ' Não'}
          </Row>
          <Row className="p-4">
            Transferir atendimentos: {transferirAtendimentos ? ' Sim' : ' Não'}
          </Row>{' '}
          <Row className="p-4">
            apenas carteira própria e com clientes criados pelos usuários:{' '}
            {transferirApenasAtendimentosComPessoasCriadasPeloUsuario
              ? ' Sim'
              : ' Não'}
          </Row>
          <Row className="p-4">
            Transferir imóveis: {transferirImoveis ? ' Sim' : ' Não'}
          </Row>
        </Col>
      ),
    },
  ].filter((i) => !!i);

  function onSave() {
    setIsLoading(true);
    form.validateFields().then(async (dados) => {
      setIsLoading(true);

      const {
        removerUsuarioDasEquipes,
        transferirApenasAtendimentosComPessoasCriadasPeloUsuario,
        transferirImoveis,
        removerUsuarioDaEmpresaAtual,
        transferirAtendimentos,
      } = finalValues;
      const empresaIdAtual = localStorage.getItem('icaseEmpresaId');
      let payload = {
        empresaIdAtual,
        empresaIdAlvo: selectedEmpresaIdAlvo,
        removerUsuarioDaEmpresaAtual,
        transferirAtendimentos,
        removerUsuarioDasEquipes,
        transferirApenasAtendimentosComPessoasCriadasPeloUsuario,
        transferirImoveis,
      };
      api
        .put(url.USUARIO.TRANSFERIR(userId), payload)
        .then((res) => {
          Notify(
            'success',
            'Transferencia',
            'Sua transferencia foi realizada com sucesso!',
          );
          setIsLoading(false);

          functionClose();
        })
        .catch((res) => {
          const mensagem = res?.response?.data?.details;
          Notify(
            'error',
            'Transferencia',
            mensagem || 'Ocorreu um erro inesperado.',
          );
          setIsLoading(false);
        });
    });
  }

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <Fragment>
      <Page loadPage={false}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardBody>
                {isLoading ? (
                  <SkeletonTableBasic />
                ) : (
                  <Row>
                    <Col xs="12">
                      <div className="my-2 p-2">
                        <Form form={form} scrollToFirstError>
                          <Steps
                            type="inline"
                            current={currentStep}
                            items={items}
                          />
                          <div style={contentStyle}>
                            {steps[currentStep].content}
                          </div>
                          {
                            <div
                              style={{
                                marginTop: 24,
                              }}>
                              <Row>
                                <Col xs="12">
                                  <Row>
                                    {currentStep < steps.length - 1 && (
                                      <Col xs="12" md="3">
                                        <ButtonShared
                                          onClick={nextStep}
                                          icon
                                          labelPosition="left"
                                          size="medium"
                                          fluid
                                          color="violet">
                                          <Icon name="angle double right" />
                                          Avançar
                                        </ButtonShared>
                                      </Col>
                                    )}
                                    {currentStep === steps.length - 1 && (
                                      <Col xs="12" md="3">
                                        <ButtonShared
                                          onClick={onSave}
                                          icon
                                          labelPosition="left"
                                          size="medium"
                                          fluid
                                          color="violet"
                                          isloading={isLoading.toString()}
                                          loadtext={'Transferindo...'}>
                                          <Icon name="check" />
                                          {'Transferir'}
                                        </ButtonShared>
                                      </Col>
                                    )}
                                    {currentStep > 0 && (
                                      <Col xs="12" md="3">
                                        <ButtonShared
                                          icon
                                          labelPosition="left"
                                          size="medium"
                                          fluid
                                          onClick={() => previousStep()}>
                                          <Icon name="reply" />
                                          Voltar
                                        </ButtonShared>
                                      </Col>
                                    )}
                                    <Col xs="12" md="3">
                                      <ButtonShared
                                        icon
                                        labelPosition="left"
                                        size="medium"
                                        color="red"
                                        fluid
                                        onClick={() => ''}>
                                        Cancelar
                                      </ButtonShared>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          }
                        </Form>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ModalTransferirUsuario;
